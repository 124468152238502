import { format, parseISO } from "date-fns";
import { nb } from "date-fns/locale";

function parse(date: string | Date) {
  if (typeof date === "string") {
    return parseISO(date);
  }
  return date;
}

export function formatDate(
  date: string | Date,
  frmat = "dd MMMM yyyy",
  locale = nb,
) {
  return format(parse(date), frmat, { locale: locale });
}
