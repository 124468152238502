/**
 * @file mortgage-providers.ts
 * This file is generated automatically from apps/setup/process/_config/mortgageProviders.ts.
 * Do not edit this file directly.
 * Instead, run 'make generate-config' or 'make push' to update this file.
 */
export const MortgageProviderIds = {
  TOLGA_OS: "tos",
  NIDAROS: "nidaros-sparebank",
  STADSBYGD: "stbank",
  AASEN: "aasen-sparebank",
  ORLAND: "orland-sparebank",
  "68_NORD": "68nord",
  DRANGEDAL: "drangedalsparebank",
  ASKIM_SPYDEBERG: "asbank",
  SELBU: "selbusparebank",
  SPAREBANKEN_DIN: "sparebankendin",
  CULTURA: "cultura",
  LOKALBANK: "lokalbank",
};

export type MortgageProviderId =
  (typeof MortgageProviderIds)[keyof typeof MortgageProviderIds];

export const MortgageProvidersOrganizationNumber = {
  TOLGA_OS: "816793432",
  NIDAROS: "937902719",
  STADSBYGD: "937902352",
  AASEN: "937903502",
  ORLAND: "937901925",
  "68_NORD": "937905378",
  DRANGEDAL: "937891601",
  ASKIM_SPYDEBERG: "937885199",
  SELBU: "937901836",
  SPAREBANKEN_DIN: "937891512",
  CULTURA: "977041244",
};

type MortgageProviderOrganizationNumber =
  (typeof MortgageProvidersOrganizationNumber)[keyof typeof MortgageProvidersOrganizationNumber];

export interface MortgageProvider {
  name: string;
  organizationNumber: MortgageProviderOrganizationNumber;
  id: MortgageProviderId;
  domainId: string;
  homePage: string;
  smsSender: string;
}

export const mortgageProviders: MortgageProvider[] = [
  {
    id: MortgageProviderIds.TOLGA_OS,
    name: "Tolga Os Sparebank",
    organizationNumber: MortgageProvidersOrganizationNumber.TOLGA_OS,
    domainId: "1885",
    homePage: "https://www.tos.no",
    smsSender: "TOS",
  },
  {
    id: MortgageProviderIds.NIDAROS,
    name: "Nidaros Sparebank",
    organizationNumber: MortgageProvidersOrganizationNumber.NIDAROS,
    domainId: "4358",
    homePage: "https://www.nidaros-sparebank.no",
    smsSender: "NidarosSpar",
  },
  {
    id: MortgageProviderIds.STADSBYGD,
    name: "Stadsbygd Sparebank",
    organizationNumber: MortgageProvidersOrganizationNumber.STADSBYGD,
    domainId: "4336",
    homePage: "https://www.stbank.no",
    smsSender: "stbank",
  },
  {
    id: MortgageProviderIds.AASEN,
    name: "Aasen Sparebank",
    organizationNumber: MortgageProvidersOrganizationNumber.AASEN,
    domainId: "4484",
    homePage: "https://www.aasen-sparebank.no",
    smsSender: "AasenSpbank",
  },
  {
    id: MortgageProviderIds.ORLAND,
    name: "Ørland Sparebank",
    organizationNumber: MortgageProvidersOrganizationNumber.ORLAND,
    domainId: "4290",
    homePage: "https://www.orland-sparebank.no",
    smsSender: "OrlandSpb",
  },
  {
    id: MortgageProviderIds["68_NORD"],
    name: "Sparebank 68 Nord",
    organizationNumber: MortgageProvidersOrganizationNumber["68_NORD"],
    domainId: "4730",
    homePage: "https://www.68nord.no",
    smsSender: "Spb68Nord",
  },
  {
    id: MortgageProviderIds.DRANGEDAL,
    name: "Drangedal Sparebank",
    organizationNumber: MortgageProvidersOrganizationNumber.DRANGEDAL,
    domainId: "2635",
    homePage: "https://www.drangedalsparebank.no",
    smsSender: "Drdalbank",
  },
  {
    id: MortgageProviderIds.ASKIM_SPYDEBERG,
    name: "Askim & Spydeberg Sparebank",
    organizationNumber: MortgageProvidersOrganizationNumber.ASKIM_SPYDEBERG,
    domainId: "1100",
    homePage: "https://www.asbank.no",
    smsSender: "ASbank",
  },
  {
    id: MortgageProviderIds.SELBU,
    name: "Selbu Sparebank",
    organizationNumber: MortgageProvidersOrganizationNumber.SELBU,
    domainId: "4285",
    homePage: "https://www.selbusparebank.no",
    smsSender: "SelbuSpbank",
  },
  {
    id: MortgageProviderIds.SPAREBANKEN_DIN,
    name: "Sparebanken DIN Telemark",
    organizationNumber: MortgageProvidersOrganizationNumber.SPAREBANKEN_DIN,
    domainId: "2630",
    homePage: "https://www.sparebankendin.no",
    smsSender: "SpbDIN",
  },
  {
    id: MortgageProviderIds.CULTURA,
    name: "Cultura Sparebank",
    organizationNumber: MortgageProvidersOrganizationNumber.CULTURA,
    domainId: "1254",
    homePage: "https://www.cultura.no",
    smsSender: "CulturaBank",
  },
];
