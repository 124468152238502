import { mortgageProviders } from "../generated/mortgage-providers";

interface SearchField {
  name: string;
  value: string;
  queryOperator?: "equals" | "contains";
  process?: string;
  type?: "text" | "select" | "date";
  multiple?: boolean;
  options?: { label: string; value: string }[];
}

const searchFields: SearchField[] = [
  {
    name: "lastName",
    value: "data.stakeholders.legalPerson.lastName",
    type: "text",
    queryOperator: "contains",
  },
  {
    name: "nationalId",
    value: "data.stakeholders.nationalId",
    type: "text",
    queryOperator: "contains",
  },
  {
    name: "phoneNumber",
    value: "data.stakeholders.phoneNumber",
    type: "text",
    queryOperator: "contains",
  },
  {
    name: "email",
    value: "data.stakeholders.email",
    type: "text",
    queryOperator: "contains",
  },
  {
    name: "bank",
    value: "data.mortgageProvider.id",
    type: "select",
    options: mortgageProviders.map((provider) => ({
      label: provider.name,
      value: provider.id,
    })),
  },
];

interface SearchConfiguration {
  search: {
    fields: SearchField[];
  };
  searchProcesses: string[];
  searchQueryView: string;
}

export const searchConfiguration: SearchConfiguration = {
  search: {
    fields: searchFields,
  },
  searchProcesses: ["mortgage"],
  searchQueryView: "searchQueryView",
};
