export function formatMoney(
  value: number | string,
  prefix = false,
  decimals = 0,
) {
  const numericValue = typeof value === "string" ? parseFloat(value) : value;

  const formattedValue = new Intl.NumberFormat("no-NB", {
    style: prefix ? "currency" : "decimal",
    currency: "NOK",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(isNaN(numericValue) ? 0 : numericValue);

  return prefix ? formattedValue : `${formattedValue} kr`;
}
