import { mortgageProviders } from "../generated/mortgage-providers";


const queueDefinition = mortgageProviders.reduce((queries, provider) => {
  const providerFilter = `?view=queue-view&data.mortgageProvider.id=${provider.id}`;
  queries[`${provider.id}-active-cases-queue`] = {
    name: `${provider.id}-active-cases-queue`,
    label: "active-cases-label",
    queueFilter: `${providerFilter}&status=active&hasIncidents=false`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 30_000,
    limit: 20,
  };
  queries[`${provider.id}-failed-cases-queue`] = {
    name: `${provider.id}-failed-cases-queue`,
    label: "failed-cases-label",
    queueFilter: `${providerFilter}&status=active&hasIncidents=true`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 30_000,
    limit: 20,
  };
  queries[`${provider.id}-completed-cases-queue`] = {
    name: `${provider.id}-completed-cases-queue`,
    label: "completed-cases-label",
    queueFilter: `${providerFilter}&status=completed`,
    queueSort: "sort=createdAt&dir=-1",
    pollingEnabled: false,
    limit: 20,
  };

  return queries;
}, {});

export { queueDefinition };
