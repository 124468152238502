import { ContactInfo } from "../../types/contactInfo";

export function ContactInfoView({ contact }: { contact: ContactInfo }) {
  return (
    <div className="container">
      <div className="info-box">MLT</div>
      <div className="content-grid">
        <span className="contact-info even">
          Telefonnummer:
          <a className="phone-link" href={`tel:${contact.phoneNumber}`}>
            {contact.phoneNumber}
          </a>
        </span>

        <span className="contact-info odd">
          Epost:
          <a className="email-link" href={`mailto:${contact.email}`}>
            {contact.email}
          </a>
        </span>
      </div>
    </div>
  );
}
