import { isEven } from "../../utils/isEven";
import { formatNationalId } from "../../utils/formatNationalId";
import { Stakeholder } from "../../types/applicant";

interface PersonData {
  key: "fullName" | "phoneNumber" | "nationalId" | "email";
  value: string;
}

export function Person({ person }: { person: Stakeholder }) {
  const legalPerson = person?.legalPerson ?? {};
  const personDataMap: PersonData[] = [
    ...("firstName" in legalPerson && "lastName" in legalPerson
      ? [
          {
            key: "fullName",
            value: `${legalPerson?.firstName || ""} ${legalPerson?.lastName || ""}`,
          },
        ]
      : []),
    "phoneNumber" in person && {
      key: "phoneNumber",
      value: person.phoneNumber,
    },
    "nationalId" in person && {
      key: "nationalId",
      value: person.nationalId,
    },
    "email" in person && {
      key: "email",
      value: person.email,
    },
  ].filter((elem): elem is PersonData => elem && elem.value !== "");

  return (
    <div className="container">
      <div className="info-box">
        {person?.role === "coApplicant" ? "MLT" : "HLT"}
      </div>
      <div className="content-grid">
        {personDataMap.map((data: PersonData, index: number) =>
          data.key === "phoneNumber" ? (
            <a
              key={`phone-nr-link-${index}`}
              className={`phone-link ${isEven(index) ? "even" : "odd"}`}
              href={`tel:${data.value}`}
            >
              {data?.value}
            </a>
          ) : data.key === "email" ? (
            <div key={`mail-link-${index}`}>
              <a
                className={`email-link ${isEven(index) ? "even" : "odd"}`}
                href={`mailto:${data?.value}`}
              >
                {data?.value}
              </a>
            </div>
          ) : (
            <div
              key={`${data.value}-display-${index}`}
              className={`data-display ${data.key === "nationalId" && "national-id"} ${data.key === "fullName" && "name"}  ${isEven(index) ? "even" : "odd"}`}
            >
              {data.key === "nationalId" && !!data.value
                ? formatNationalId(data.value)
                : data.value.toLocaleLowerCase()}
            </div>
          ),
        )}
      </div>
    </div>
  );
}
