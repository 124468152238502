import { TaskProps } from "../../types/taskProps";
import { Stakeholder } from "../../types/applicant";
import { ContactInfo } from "../../types/contactInfo";
import { Person } from "../common/Person";
import { formatDate } from "../../utils/formatDate";
import { formatMoney } from "../../utils/formatMoney";
import { ContactInfoView } from "../common/ContactInfoView";

export function CaseSummary({ caseData, t }: TaskProps) {
  const applicants = caseData?.data?.stakeholders;
  const coApplicantsContactInfo = caseData?.data?.coApplicantsContactInfo;

  const hasAddedCoApplicantAsStakeholder =
    coApplicantsContactInfo?.length > 0 &&
    applicants.some(
      (stakeholder: Stakeholder) => stakeholder.role === "coApplicant",
    );

  return (
    <div className="caseSummary">
      <section className="wrapper">
        <div className="split">
          {applicants?.map((applicant: Stakeholder, index: number) => {
            return <Person key={`${index}_applicant`} person={applicant} />;
          })}
        </div>
        {!hasAddedCoApplicantAsStakeholder && (
          <div className="split">
            {coApplicantsContactInfo?.map(
              (coApplicant: ContactInfo, index: number) => {
                return (
                  <ContactInfoView
                    contact={coApplicant}
                    key={`${index}_contact`}
                  />
                );
              },
            )}
          </div>
        )}
        <div>
          <span className="summary">
            <div className="summary-box">
              <p className="">Saksdetaljer</p>
            </div>

            <ul className="list">
              <li className="list-item">
                <p>{t("loanPurpose")}</p>
                <p>{t(caseData?.data?.loanPurpose)}</p>
              </li>
              <li className="list-item">
                <p>{t("created-at")}</p>
                <p>{formatDate(caseData?.createdAt, "dd.MM.yyyy")}</p>
              </li>
              <li className="list-item">
                <p>{t("amount")}</p>
                <p>{formatMoney(caseData?.data?.totalLoanAmount)}</p>
              </li>
              <li className="list-item">
                <p>{t("due-date")}</p>
                <p>{t(caseData?.data?.requestedLoans?.[0]?.dueDate)}</p>
              </li>
            </ul>
          </span>
        </div>
      </section>
    </div>
  );
}
