import { FlowContext } from "../hooks/useFlowContext";
import { CaseSummary } from "../components/CaseSummary";

const caseConfig = {
  caseSummary: {
    component: (props) => {
      return (
        <FlowContext.Provider value={props}>
          <CaseSummary {...props} />
        </FlowContext.Provider>
      );
    },
  },
};

export { caseConfig };
